import { getAlerts, getSearchAlerts, publish, unpublish } from "../../api";

export const alertsTypes = {
  LOAD: "LOAD_ALERTS",
  LOAD_SUCCESS: "LOAD_ALERTS_SUCCESS",
  LOAD_FAILED: "LOAD_ALERTS_FAILED",

  SEARCH: "SEARCH_ALERTS",
  SEARCH_SUCCESS: "SEARCH_ALERTS_SUCCESS",
  SEARCH_FAILED: "SEARCH_ALERTS_FAILED",
  SEARCH_CLEAR: "SEARCH_ALERTS_CLEAR",

  PUBLISH: "PUBLISH_ALERT",
  PUBLISH_FAILED: "PUBLISH_ALERT_FAILED",
  PUBLISH_SUCCESS: "PUBLISH_ALERT_SUCCESS",

  UNPUBLISH: "UNPUBLISH_ALERT",
  UNPUBLISH_FAILED: "UNPUBLISH_ALERT_FAILED",
  UNPUBLISH_SUCCESS: "UNPUBLISH_ALERT_SUCCESS",

  UPDATE: "UPDATE_ALERT",
};

export const loadAlerts = (page, type, search) => async (dispatch) => {
  try {
    const alerts = await getAlerts(page, type, search);
    dispatch({
      type: alertsTypes.LOAD_SUCCESS,
      payload: alerts,
    });
  } catch (error) {
    const msg = !type
      ? "Erro ao carregar os alertas!"
      : type === "published"
      ? "Não há alertas publicados!"
      : "Não há alertas não publicados!";

    dispatch({
      type: alertsTypes.LOAD_FAILED,
      payload: { message: msg },
    });
  }
};

export const clearSearchAlerts = () => async (dispatch) => {
  dispatch({
    type: alertsTypes.SEARCH_CLEAR,
  });
};

export const searchAlerts = (page, search) => async (dispatch) => {
  dispatch({
    type: alertsTypes.SEARCH,
  });
  try {
    const alerts = await getSearchAlerts(page, search);
    dispatch({
      type: alertsTypes.SEARCH_SUCCESS,
      payload: {
        data: alerts.data,
        page,
        search,
        type: null,
      },
    });
  } catch (error) {
    dispatch({
      type: alertsTypes.LOAD_FAILED,
    });
  }
};

export const publishAlert = (id) => async (dispatch) => {
  try {
    const resp = await publish(id);

    dispatch({
      type: alertsTypes.PUBLISH_SUCCESS,
      payload: { data: resp, id },
    });

    dispatch({
      type: "SHOW_POPUP",
      payload: { id: 0, type: "publish-success" },
    });
  } catch (error) {
    dispatch({
      type: alertsTypes.PUBLISH_FAILED,
      payload: { message: "Erro ao publicar o alerta" },
    });
  }
};

export const unpublishAlert = (id) => async (dispatch) => {
  try {
    const resp = await unpublish(id);
    dispatch({
      type: alertsTypes.UNPUBLISH_SUCCESS,
      payload: { data: resp, id },
    });
    dispatch({
      type: "HIDE_POPUP",
    });
  } catch (error) {
    dispatch({
      type: alertsTypes.UNPUBLISH_FAILED,
      payload: { message: "Erro ao cancelar publicação do alerta" },
    });
  }
};
