import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import { Link } from "react-router-dom";
import { fonts, rem } from "../../assets/styles";
import { showPopUp } from "../../store/popup";
import Ativar from "../../assets/Publicado";
import Remover from "../../assets/Remover";
import Editar from "../../assets/Editar";

const Row = (props) => {
  const {
    business_role,
    company,
    created,
    email,
    first_name,
    last_name,
    is_active,
    phone,
    persona,
    filters,
    id,
  } = props;

  const _created = moment(created).utc().format("DD/MM/YYYY");

  function confirme(id, type) {
    props.showPopUp(id, type);
  }

  return (
    <Tr>
      <td>{_created}</td>
      <td>
        <Link to={`/contact/edit/${id}`} className="links">
          {first_name} {last_name}
        </Link>
      </td>
      <td>{email}</td>
      <td>{phone}</td>
      <td>{business_role}</td>
      <td style={{ textAlign: 'center' }}>{company ? company.name : ""}</td>
      <td
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "40px",
        }}
      >
        {filters.map((item) => (
          <Bag style={{marginRight: 5}}>{item.name}</Bag>
        ))}
      </td>
      <td>{is_active ? "Ativo" : "Inativo"}</td>
      <td>
        <Buttons>
          {is_active && (
            <button
              onClick={() => confirme(id, "confirm-contact-inactive")}
              className="active"
              title="Ativar"
            >
              <Ativar />
            </button>
          )}
          {!is_active && (
            <button
              onClick={() => confirme(id, "confirm-contact-active")}
              className="inactive"
              title="Inativar"
            >
              <Ativar />
            </button>
          )}

          <Link to={`/contact/edit/${id}`} className="edit" title="Editar">
            <Editar />
          </Link>

          <button
            onClick={() => {
              confirme(id, "confirm-contact-delete");
            }}
            className="delete"
            title="Deletar"
          >
            <Remover />
          </button>
        </Buttons>
      </td>
    </Tr>
  );
};

Row.propTypes = {
  showPopUp: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  popup: state.popup,
  showPopUp: state.popup.showPopUp,
});

export default connect(mapStateToProps, { showPopUp })(Row);

const Tr = styled.tr`
  &:nth-child(odd) {
    background: #e0e0e066;
  }

  &:hover {
    background: #e1e1e1;
  }

  height: 40px;

  width: 100%;

  td {
    font-size: 12px !important;
    border: none;
  }

  td:nth-child(1) {
    width: 70px;
    text-align: center;
    border-right: 2px solid gainsboro;
  }
  td:nth-child(2) {
    min-width: 160px;
    border-right: 2px solid gainsboro;
    padding-left: 5px;
    text-transform: uppercase;
  }
  td:nth-child(3) {
    border-right: 2px solid gainsboro;
    padding-left: 5px;
  }
  td:nth-child(4) {
    min-width: 110px;
    border-right: 2px solid gainsboro;
    padding-left: 5px;
  }
  td:nth-child(5) {
    min-width: 110px;
    border-right: 2px solid gainsboro;
    padding-left: 5px;
  }
  td:nth-child(6) {
    min-width: 180px;
    border-right: 2px solid gainsboro;
    padding-left: 5px;
    font-weight: 500;
    font-size: 12px !important;
  }
  td:nth-child(7) {
    border-right: 2px solid gainsboro;
    padding-left: 5px;
    min-width: 150px;
  }
  td:nth-child(8) {
    width: 50px;
    text-align: center;
  }
  td:nth-child(9) {
    width: 100px;
  }
  .links:hover {
    color: #f05740;
  }
`;

const Buttons = styled.div`
  display: flex;
  button,
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border: none;
    font-size: 14px;
    margin: 0 3px;
    border-radius: 3px;
    color: #fff;
    text-decoration: none;
    outline: none;
    &.delete {
      background-color: #f05740;
    }
    &.edit {
      background-color: #03a9f4;
    }
    &.active {
      background-color: #4ca845;
    }
    &.inactive {
      background-color: #eee;
      &::after {
        content: "";
        position: absolute;
        width: 20px;
        height: 1px;
        border-top: solid 1px #666;
        transform: rotate(-45deg);
        z-index: 5;
      }
    }

    svg {
      margin: auto;
      width: 16px;
      height: 16px;
      fill: currentColor;
    }
  }
`;

const Bag = styled.div`
  background: #dc4735;
  color: white;
  display: inline-block;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 5px;
  padding: 4px 4px;
  font-size: 9px;
  text-align: center;
`;
