import http, { cmsURL } from "./http.service";
import { PATCH, POST } from "../components/utils/contants";
/*
 Alerts API
*/

/**
 * Create Alert
 */
export function create(alert) {
  const tags = alert.tags.replace(/ /g, "").split(",");
  const data = {
    contact_filters: alert.verticals,
    plans: alert.plans,
    title: alert.title,
    content: alert.content,
    tags,
    assets: alert.assets,
    custom_delivery: alert.custom_delivery
  };

  return http
    .post(`${cmsURL}/alerts/publications/`, data)
    .then((response) => response)
    .catch((error) => error.response);
}

/**
 * Create Alert - Asset
 */
export function createAlert(alert) {

  const tags = alert.tags.replace(/ /g, "").split(",");
  const data = {
    contact_filters: alert.verticals,
    plans: alert.plans,
    title: alert.title,
    content: alert.content,
    tags,
    assets: alert.assets,
  };

  return http
    .post(`${cmsURL}/alerts/publications/`, data)
    .then((response) => response)
    .catch((error) => error.response);
}

export function getPlans() {
  const nURL = `${cmsURL}/alerts/personas/`;
  return http.get(nURL).then(({ data }) => data);
}

export function getVerticals() {
  const nURL = `${cmsURL}/alerts/contact-filter/`;
  return http.get(nURL).then(({ data }) => data.results);
}

export function getAlerts(page, type, search) {
  const params = new URLSearchParams({
    limit: 10,
    page: page,
    ...(type && { status: type }),
    ...(search && { search: search }),
  });
  const nURL = `${cmsURL}/alerts/publications/?${params.toString()}`;

  return http.get(nURL).then(({ data }) => ({ data, page, type, search }));
}

export function getSearchAlerts(page, search) {
  return getAlerts(page, null, search);
}

export function getAlertByID(id) {
  return http
    .get(`${cmsURL}/alerts/publications/${id}/`)
    .then(({ data }) => data);
}

export function setAlertByID(id, data) {
  return http.patch(`${cmsURL}/alerts/publications/${id}/`, {
    contact_filters: data.verticals,
    plans: data.plans,
    title: data.title,
    content: data.content,
    tags: data.tags.replace(/ /g, "").split(","),
    assets: data.assets,
    custom_delivery: data.custom_delivery
  });
}

export function publish(id) {
  return http
    .post(`${cmsURL}/alerts/publications/${id}/publish/`)
    .then(({ data }) => data);
}

export function unpublish(id) {
  return http
    .post(`${cmsURL}/alerts/publications/${id}/unpublish/`)
    .then(({ data }) => data)
    .catch((error) => error.response);
}

/*
 Contacts API
*/

export function activeContact(id) {
  return http
    .patch(`${cmsURL}/alerts/contacts/${id}/activate/`)
    .then((response) => response)
    .catch((error) => {
      throw error.response;
    });
}

export function inactiveContact(id) {
  return http
    .patch(`${cmsURL}/alerts/contacts/${id}/inactivate/`)
    .then((response) => response)
    .catch((error) => {
      throw error.response;
    });
}

export function deleteContact(id) {
  return http
    .delete(`${cmsURL}/alerts/contacts/${id}/`)
    .then((response) => response)
    .catch((error) => {
      throw error.response;
    });
}

export function getContacts(page, type) {
  const URL = `${cmsURL}/alerts/contacts?limit=10&page=${page}`;
  return http
    .get(URL)
    .then(({ data }) => ({
      data,
      page,
      type,
    }))
    .catch((error) => {
      throw error.response;
    });
}

export async function newContact(contact) {
  return http
    .post(`${cmsURL}/alerts/contacts/`, contact)
    .then((response) => response)
    .catch((error) => {
      throw error.response;
    });
}

export function setContactByID(id, data) {
  return http
    .put(`${cmsURL}/alerts/contacts/${id}/`, data)
    .then((response) => response)
    .catch((error) => {
      throw error.response;
    });
}

export async function getData() {
  const requestCompany = await http.get(`${cmsURL}/alerts/companies/`);
  const requestPlans = await http.get(`${cmsURL}/alerts/personas/`);
  const requestVerticals = await http.get(`${cmsURL}/alerts/verticals/`);
  const requestFilters = await http.get(`${cmsURL}/alerts/contact-filter/`);
  return {
    companies: requestCompany.data,
    plans: requestPlans.data,
    verticals: requestVerticals.data.results,
    filters: requestFilters.data.results,
  };
}

export async function getContactByID(id) {
  const requestContacts = await http.get(`${cmsURL}/alerts/contacts/${id}`);
  const requestCompany = await http.get(`${cmsURL}/alerts/companies/`);
  const requestPlans = await http.get(`${cmsURL}/alerts/personas/`);
  const requestVerticals = await http.get(`${cmsURL}/alerts/verticals/`);
  const requestFilters = await http.get(`${cmsURL}/alerts/contact-filter/`);
  return {
    contacts: requestContacts.data,
    companies: requestCompany.data,
    plans: requestPlans.data,
    verticals: requestVerticals.data.results,
    filters: requestFilters.data.results,
  };
}

export function getAssetTags() {
  const URL = `${cmsURL}/contents/asset-tags/`;
  return http
    .get(URL)
    .then(({ data }) =>
      data.results.map((tag) => ({
        value: tag.id,
        label: tag.name,
      }))
    )
    .catch((error) => {
      throw error.response;
    });
}

/**
 * GetAssetsURLS
 * @param {string} tagData
 * @param {string} assetID
 * @param {"post" | "patch"} type
 * @return {Promise}
 */
export async function getAssetURLs(tagData, assetID, type = "post") {
  return new Promise((resolve, reject) => {
    const tagOutput = { tag: tagData.tag ?? tagData };
    if (type === POST) {
      return http
        .post(`${cmsURL}/contents/assets/`, tagData)
        .then((response) => resolve(response))
        .catch((error) => reject(error.response));
    }
    else {
      return http
        .patch(`${cmsURL}/contents/assets/${assetID}`, tagOutput)
        .then((response) => resolve(response))
        .catch((error) => reject(error.response));
    }
  });
}

/**
 * Update Main Asset
 * @param {string} tag
 * @param {string} id
 * @param {string} text
 * @return {Promise}
 */
export async function updateMainAsset(assetID, label) {
  const tagOutput = { leading: true, label }

  return new Promise((resolve, reject) => {
    return http
      .patch(`${cmsURL}/contents/assets/${assetID}`, tagOutput)
      .then((response) => resolve(response))
      .catch((error) => {
        reject(error.response)
      });
  })
}

/**
 * Upload file to S3
 * @param {string} url
 * @param {File} file
 * @return {Promise}
 */
export async function uploadFile(url, file) {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'PUT',
      body: file[0].file
    })
      .then(res => resolve(res))
      .catch(error => reject(error))
  })
}
/**
 * Delete file from AWS S3
 * @param {number} id
 * @return {Promise}
 */
export async function deleteFile(id) {
  return new Promise((resolve, reject) => {
    return http
      .delete(`${cmsURL}/contents/assets/${id}`)
      .then((response) => resolve(response))
      .catch((error) => reject(error.response));
  });
}
